
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Videos',
  data() {
    return {
      videos: [
        // L2R
        'https://www.youtube.com/embed/xQ80S-wKHgM',
        'https://www.youtube.com/embed/47g8Rvc7pa4',
        'https://www.youtube.com/embed/f411P3mvRP4',
        'https://www.youtube.com/embed/Q1RPbAVuMDY',
        // WoW
        'https://www.youtube.com/embed/mJsI44Ro26M',
        'https://www.youtube.com/embed/fNYnnIpntGU',
        'https://www.youtube.com/embed/Qv7jOStge4Y',
        //  GA
        'https://www.youtube.com/embed/G387pGDC_so',
        'https://www.youtube.com/embed/zt1y3JMsj9o',
        // Warhammer Online
        'https://www.youtube.com/embed/5IgMbWS2nHs',
        // L2
        'https://www.youtube.com/embed/bHAkUduxgQY',
        'https://www.youtube.com/embed/-XlDd13rl5U',
        'https://www.youtube.com/embed/2RH543QPeAo'
      ]
    };
  }
});
